//****************************************/
//*********     Window Load    ***********/
//****************************************/


//- Preloader
$(window).on('load', function() {
  $("#over").fadeOut().remove();
  $("#preload").delay(550).fadeOut("slow").remove();
});


//****************************************/
//******     Document Ready    ***********/
//****************************************/

$(document).ready(function () {

  let lang=document.getElementsByTagName("html")[0].getAttribute("lang"); //определяем язык страницы
  let url = window.location.pathname;
  let filename = url.substring(url.lastIndexOf('/')+1);

  // Smooth scroll
  smoothScroll();

  // Accordion (FAQ)
  accordion();

  // Imposta anno
  setYear();

  // Apre/chiude menu lingua
  $('.lang').click(function () {
    $('.lang__submenu').toggleClass('lang__submenu--visible');
  })

  //- Apre/chiude menu principale
  $(".menu-button").click(function () {
    $(this).toggleClass("menu-button--to-open"),
    $(this).toggleClass("menu-button--to-close"),
    $(this).hasClass("menu-button--to-close") ? $(".menu").addClass("menu--visible") : $(".menu").removeClass("menu--visible")
  });

  //- инициализируем полный список всех направлений на нужном языке (объект namesLocations в файле locations.js: ключ+значение)
  getNamesLocationsByLanguage(lang);

  //- Aggiunge le direzioni in FROM
  generateFrom();

  if ((filename == "") || (filename == "index.html")){
      $('#mainform')[0].reset();  //- serve quando ricarichiamo la pagina
      resetPassengers(); // set valori di default
      setPrices();
  }
  
  if (filename == "booking-transfer.php") {
    addCalendar();
    moveInfoFromMainToBooking();
  }

  if (filename==="booking-excursion.php" || filename==="booking-transfer-winery.php"){
    addCalendar();
    moveInfoExcursionToBooking(filename);
  }


  //==========================================================//
  //=================== BOOKING TRANSFER ==================== //
  //==========================================================//

  // Инвертирование направлений
  $('.btn-inverse').click(function () {
    if(($('select[name="from"]').val()) && ($('select[name="to"]').val())) {
      var location1=$('select[name="from"]').val();
      var location2=$('select[name="to"]').val();
      $('select[name="from"]').val(location2);
      $('select[name="from"]').change();
      $('select[name="to"]').val(location1);
    }
    calcPrice();
  }); 

  //- Изменение FROM генерирует новый список направлений в TO
  $('select[name="from"]').change(generateTo); 

  //- Пересчет стоимости при изменении TO
  $('select[name="to"]').change(function () {
    if($('select[name="from"]').val()!=null){
      calcPrice();    
    }
  });

  // Пересчет стоимости при изменении флажка "Add round-trip" 
  $('input[name="roundtrip"]').click(calcPrice);

   // Показывает предупреждение при клике на телефон
  $('input[name="phone"]').click(function(){
    if(!$(this).hasClass('was-alerted')){
        $("#phoneModal .modal").removeClass('hidden');
        $("#phoneModal .modal__content").removeClass('hidden');  
        $(this).addClass('was-alerted');  
    }
  });

  // Убирает ошибку (рамку) при клике на мессенджер 
  $("input[name='messengers']").click(function() { 
    $(".form__messengers").removeClass('form__messengers--err');
  });
  

  //=================== ВЫБОР ПАССАЖИРОВ =================//

  // Как только поле "Пассажиры" получает фокус, открываем подменю и убираем фокус с поля "Пассажиры", 
  // чтобы функция правильно срабатывала при мгновенном повторном нажатии на "Пассажиры""
  $('input[name="passengers"]').focusin(function(){
    $(".passengers__submenu").toggleClass("hidden");
    $(this).blur();
  });

  $(".increase").click(function(){
    changePassengers($(this), ".increase", 0);
  });

  $(".decrease--children").click(function () {
    changePassengers($(this), ".decrease", 0);
  });

  $(".decrease--adults").click(function () {
    changePassengers($(this), ".decrease", 1);
  });

  $('.passengers__ok').click(function(){
    $('.passengers__submenu').addClass('hidden'); 
  })

  $('.passengers__reset').click(resetPassengers);


  //====================- BOOKING TRANSFER (Step 1) =====================//
  
  // Переход с главной на страницу бронирования к шагу 2.
  $(".btn--step1").click(function () {
    if(checkValidityForm("#mainform", ".modal-step1")){
      serializeMainForm();
    }else{
      return(false)
    }   
  });

    // Переход на странице бронирования к шагу 2.
  $(".btn--booking-step1").click(goToStep2); //- se goToStep2 restituisce false, l'evento [click] viene interrotto


  //====================- BOOKING TRANSFER (Step 2) =================//

  // Показываем поля в зависимости от выбора структуры (работает независимо и для структуры1 и для структуры2)
  $('.structure-group input').change(function () {
    var valStructure=$(this).val(); //=hotel1a, house1a, bb1a, hotel2, house2...
    var attrName=$(this).attr('name'); //=typeStructure3, typeStructure2, typeStructure1...
    
    $('input[name='+attrName+']').each(function(){
      var groupName=".info-"+$(this).val()+"-group";
      if($(this).val()===valStructure){
        $(groupName).removeClass("hidden");
        $(groupName+" input").prop("required", true);
      }else{ //- скрываем, делаем необязательными и очищаем поля, которые не выбраны
        $(groupName).addClass("hidden");
        $(groupName+" input").prop("required", false);
        $(groupName+" input").val("");
      }
    });
  });

 
  // Подсказки для полей в форме
  $(".help-icon").hover(function() {
    $(this).siblings('.help-content').toggleClass('hidden');
  });

  // Возврат к шагу 1
  $('.btn--tostep1, .btn-back-step2').click(function (event) {
    $('.step2').addClass('hidden');
    $('.step1').removeClass('hidden');
    window.scrollTo(0, 0);
  });

  //- Переход к шагу 3.
  $('.btn--step2').click(goToStep3);


  //====================- BOOKING TRANSFER (Step 3) =======================//

  // Возврат к шагу 2
  $('.btn-back-step3').click(function () {
    $('#step2').removeClass('hidden');
    $('#step3').addClass('hidden');
    $(".step3__changepart").addClass('hidden');
    // Reset di tutti gli input all'interno di #formsummary tranne quello con id specifico
    $('#formsummary input:not("[name=\'csrf_token\']")').val('');
    window.scrollTo(0, 0);
  });

  // Проверка и отправка итоговой формы
  $("#formsummary").submit(function (event) {
    event.preventDefault(); //- Останавливаем действие по умолчанию (отправка формы)
    if(checkValidityForm("#formsummary", ".modal-step2") === false){
      return(false);
    } else {
      doajax("#formsummary");
      $(this).removeClass("was-validated");
    }
  });

  
  //===================================================================//
  //- ======== BOOKING TRANSFER CASE VINICOLE / EXCURSIONS  ========== //
  //===================================================================//

  // Передача информации о трансфере на винодельню в форму заказа

  $(".btn-bookwinery").click(function () {
    var vartitle=$(this).parent().parent().find('.offers__card__title span').text();
    vartitle=vartitle.slice(0, -1); //- удаляем двоеточие в конце
    var varpersons=$(this).parent().find('.offers__card__npersons span').text();
    var varprice=$(this).parent().find('.offers__card__price').text();
    var objExc = {
      formtitle: vartitle,
      formpersons: varpersons,
      formprice: varprice
    };
    var serialObj = JSON.stringify(objExc); //сериализуем его
    localStorage.setItem("myKey", serialObj); //запишем его в хранилище по ключу "myKey"
  });


  // Передача информации об экскурсии в форму заказа

  $(".sendexcinfo").click(function () {
    var vartitle=$('#datatosendtitle span').text();
    var varguide=$(this).parent().parent().find('.prices__header').text();
    var varpersons=$(this).parent().find('.prices__npersons span').text();
    var varprice=$(this).parent().find('.prices__price').text();
    var objExc = {
      formtitle: vartitle,
      formguide: varguide,
      formpersons: varpersons,
      formprice: varprice
    };
    var serialObj = JSON.stringify(objExc); //сериализуем его
    localStorage.setItem("myKey", serialObj); //запишем его в хранилище по ключу "myKey"
  });


  // Проверка и отправка итоговой формы заказа на винодельню / экскурсии

  $("#bookingexc").submit(function (event) {
    event.preventDefault(); //- Останавливаем действие по умолчанию (отправка формы)
    if(checkValidityForm("#bookingexc", ".modal-step2") === false){
      return(false);
    } else {
      doajax("#bookingexc");
      $(this).removeClass("was-validated");
    }
  });


  //======================================================//
  //==================== MODAL POPUP =====================//
  //======================================================//

  //Закрытие модального окна при клике на кнопки (крестик, ОК)
  $(".modal-close").click(function () {
    $(".modal").addClass('hidden');
    $(".modal__content").addClass('hidden');
  });

  $(".modal-close-redirect").click(function () {
    $(".modal").addClass('hidden');
    $(".modal__content").addClass('hidden');
    var modalLang=$(this).parents(".modal-wrap");
    if(modalLang.hasClass('modalEn')) {
      window.location.href="/";    
    }
    else if(modalLang.hasClass('modalRu')) {
      window.location.href="/ru";    
    }
    else if(modalLang.hasClass('modalIt')) {
      window.location.href="/it";    
    }
  });


}); // End $(document).ready


//****************************************/
//******     Document Click   ***********/
//****************************************/

$(document).click(function (e){ 
    // Закрываем выбор языков по клику снаружи
    var langClick = $(".lang__submenu"); // определяем элемент, к которому будем применять условия (можем указывать ID, класс либо любой другой идентификатор элемента)
    if((langClick.hasClass("lang__submenu--visible")) && (!langClick.is(e.target)) && (!($('.lang').is(e.target))) && (!($('.lang__visible').is(e.target)))) {
      langClick.removeClass("lang__submenu--visible");
    }

    // Пассажиры
    var block1 = $(".passengers__submenu"); // определяем элемент, к которому будем применять условия (можем указывать ID, класс либо любой другой идентификатор элемента)
    var block2=$('input[name="passengers"]');
    if((!block1.hasClass("hidden")) && (!block1.is(e.target)) && (!block2.is(e.target)) && (block1.has(e.target).length === 0)) {
      block1.addClass("hidden");
    }
});


//****************************************/
//******     Window Resize   ***********/
//****************************************/

//- При изменении ширины окна браузера на ширине >=1200px сворачивается меню, если оно было развернуто.
//- срабатывает событие клика на кнопку, чтобы и меню, и кнопка приняли необходимый вид для широких экранов
$(window).resize(function(){
  if($(window).width()>=1200){
    if($('.menu').hasClass('menu--visible')){
      $('.menu-button').click();
    }
  }
});


//====================================================================//
//====================== GENERAL FUNCTIONS ========================== //
//====================================================================//

function isset(obj) {
  if (typeof obj === "string" && obj.trim() === "") {
   return false;
  }else if (typeof obj === 'undefined' || obj === null) {
    return false;
  } else {
    return true;
  }
}

//- Плавный переход к якорю
function smoothScroll() {
  $(".soft-scroll").on("click", function(e) {
    e.preventDefault();
    var target = $(this).attr("href"),
        offsetTop = $(target).offset().top;
    $("body,html").animate({
      scrollTop: offsetTop
    }, 1000);
  });
}


//- Аккордеон
function accordion() {
  $('.spoiler-body').hide();
  $(".spoiler-wrap").click(function(e) {
    e.preventDefault();
    var $spoilerBody = $(this).find('.spoiler-body'),
        $spoilerHead = $(this).find('.spoiler-head');

    if ($(this).hasClass('active')) { //- если кликнули по открытой ссылке
      $spoilerBody.slideToggle();
      $spoilerHead.removeClass("icon-minus").addClass("icon-plus");
      $(this).removeClass('active');
    } else { //- если кликнули по закрытой ссылке
      $('.spoiler-wrap').removeClass('active').find('.spoiler-body').slideUp().end().find('.spoiler-head').removeClass("icon-minus").addClass("icon-plus");
      $(this).addClass('active');
      $spoilerHead.removeClass("icon-plus").addClass("icon-minus");
      $spoilerBody.slideToggle();
    }
  });
}

//- Календарь (datepicker)
function addCalendar(){
  $.datepicker.setDefaults($.datepicker.regional['it']);
  $("input[myid='date']").datepicker({
   minDate: "+1",
   dateFormat: "d-M-yy"
  });
  $("#date").datepicker({
   minDate: "+1",
   dateFormat: "d-M-yy"
  });
  $('.ui-datepicker').addClass('notranslate');

  focusDate(); //- hack
}


//- Если у поля есть атрибут readonly, то не работает required.
//- Хак: добавляем к полю атрибут readonly при получении фокуса, удаляем - при потере
//- В этом случае пользователь не может вводить свои значения в поле

function focusDate(){
$("input[myid='date'], #date").on({
  focusin: function() {
    $(this).attr("readonly", true);
  },
  focusout: function() {
    $(this).removeAttr("readonly");
  }
});
}

//- Set year
function setYear(){
  var d = new Date();
  var output = d.getFullYear();
  $('.yearval').text(output);
}

//- Проверка формы на валидность и показ ошибки
function checkValidityForm(idForm, className){
  if ($(idForm)[0].checkValidity() === false){
    $(idForm).addClass('was-validated');
    $("#errorsModal .modal").removeClass('hidden');
    $("#errorsModal " + className).removeClass('hidden');
    return(false);
  }
  return(true);
}



//=====================================================================//
//==================== FUNCTIONS BOOKING TRANSFER =====================//
//=====================================================================//

//================ Main page (particolari) ====================//

///- Aggiungiamo i prezzi nelle offerte
function setPrices(){
  $("#offers .offers__card__price").each(function(){
    let tmp=$(this).attr('key');
    let numPax=$(this).attr('pax');
    let offerPrice;
    if ($(this).hasClass("price_taormina")){
      offerPrice=pricesTaormina[tmp][numPax-1];
    }else{
      offerPrice=pricesCataniaAirport[tmp][numPax-1];
    }   
    $(this).text("€ "+offerPrice);
  });
}

//- Сериализация
function serializeMainForm(){
  $("#mainform").removeClass("was-validated");
  var varfrom=$('#from option:selected').val();
  var varto=$('#to option:selected').val();
  var varpassengers=$('#passengers').val();
  var varpersons=$('#persons').val();
  var varadults=$('#adults').val();
  var varchildrensmall=$('#childrensmall').val();
  var varchildrenmedium=$('#childrenmedium').val();
  var varchildrenbig=$('#childrenbig').val();
  var varprice=$('#price').val();
  var varroundtrip=$('#roundTripCheck').is(":checked");

  var objExc = {
    formfrom: varfrom,
    formto: varto,
    formpassengers: varpassengers,
    formpersons: varpersons,
    formadults: varadults,
    formchildrensmall: varchildrensmall,
    formchildrenmedium: varchildrenmedium,
    formchildrenbig: varchildrenbig,
    formprice: varprice,
    formroundtrip: varroundtrip,
  };

  var serialObj = JSON.stringify(objExc); //сериализуем его
  localStorage.setItem("myKey", serialObj); //запишем его в хранилище по ключу "myKey"

  $("#mainform")[0].reset();
}


//- Перенос данных о маршруте с Главной на Booking transfer

function moveInfoFromMainToBooking(){

  var returnObj = JSON.parse(localStorage.getItem("myKey"));

  //- Если с главной страницы не поступили данные (перезагрузка или прямое открытие booking.html), то очищаем все формы и показываем шаг1
  if(returnObj == null){
    $("#step1").removeClass('hidden');
    $('#step2').addClass('hidden');
    $('#step3').addClass('hidden');
    $('#booking')[0].reset();  
    $('#booking2')[0].reset();  
    $('#formsummary')[0].reset(); 
    resetPassengers(); //пересчитываем пассажиров и стоимость
    return(false); 
  }

  //- если returnObj не пустой, то получаем из него значения полей
  let from = returnObj['formfrom'];
  let to = returnObj['formto'];
  $('#from').val(from);
  generateTo(); //- формируем список в TO на странице booking
  $('#to').val(to);
  $('#persons').val(returnObj['formpersons']);
  $('#passengers').val(returnObj['formpassengers']);
  $('#adults').val(returnObj['formadults']);
  $('#childrensmall').val(returnObj['formchildrensmall']);
  $('#childrenmedium').val(returnObj['formchildrenmedium']);
  $('#childrenbig').val(returnObj['formchildrenbig']);
  $('#roundTripCheck').prop('checked', returnObj['formroundtrip']);
  $('#price').val(returnObj['formprice']);

  $("#booking2").removeClass('was-validated'); //убирает подсветку ошибок, если мы уже вносили данные на шаге 2
  
  // Формируем текст с пунктом отправления и назначения
  let locationName1=namesLocations[$('#from').val()];
  let locationName2=namesLocations[$('#to').val()];
  let strOneway=locationName1 + '  →  ' + locationName2;
  let strRoundtrip=locationName2 + '  →  ' + locationName1;
  $("#headeroneway").text(strOneway);
  
  // Показывает/скрывает то, что относится к трансферу в обратном направлении
  if ($('#roundTripCheck').is(':checked')){
    $("#headerroundtrip").text(strRoundtrip);
    $(".tripReturn").removeClass('hidden');
    $('.tripReturn input').prop('required', true);
    $('#booking2 .heading_rettangle').removeClass('hidden');
  }else{
    $(".tripReturn").addClass('hidden');
    $('.tripReturn input').prop('required', false);
    $('#booking2 .heading_rettangle').addClass('hidden');
  }
  
  // Показывает число пассажиров, стоимость
  $("#headerpersons").text($('#passengers').val());
  $("#headerprice").text($('#price').val());

  // Изменение состояния кнопок добавить/удалить пассажира в зависимости от полученных данных
  btnIncreaseDecrease(); 

  localStorage.removeItem("myKey");

  goToStep2();  // вызываем функцию перехода от шага 1 к шагу 2 в системе бронирования
}


//================FUNCTIONS Пассажиры ====================//

//Функция обработки количества и состава пассажиров
function changePassengers(thisBtn, btnName, minVal){
  var maxSeats=8; // max число пассажирских мест в такси
  if(btnName==".increase"){
    var numGroupPassengers=parseInt(thisBtn.siblings('input').val()) + 1; //Новое число пассажиров в данной группе
    var totalPassengers=parseInt($(".passengers__total").val()) + 1; //Увеличиваем значение в счетчике пассажиров
    var btnToEnable=".decrease";
  }
  else if(btnName==".decrease"){
    var numGroupPassengers=parseInt(thisBtn.siblings('input').val()) - 1;
    var totalPassengers=parseInt($(".passengers__total").val()) - 1; 
    var btnToEnable=".increase";
    if(numGroupPassengers==minVal){
       thisBtn.prop("disabled", true);
    }  
  }

  $(".passengers__total").val(totalPassengers); //Устанавливаем новое значение в счетчике пассажиров
  thisBtn.siblings('input').val(numGroupPassengers); //Изменяем число пассажиров в данной группе
  if(thisBtn.siblings(btnToEnable).prop("disabled", true)){
    thisBtn.siblings(btnToEnable).prop("disabled", false);
  }
  var totalAdults=parseInt($('input[name="adults"]').val());
  var totalChildren=totalPassengers-totalAdults;
  var freeSeats=maxSeats-totalPassengers; //считаем количество свободных мест
  var strPassengers=$(".label-adults").text() + ": " + totalAdults + "; " + $(".label-children").text() + ": " +  + totalChildren;
  $('input[name="passengers"]').val(strPassengers);
  if(freeSeats<=0){
    $(".increase").prop("disabled", true); //если свободных мест нет, блокируем все кнопки добавить
  } else {
    $(".increase").prop("disabled", false); //иначе активируем кнопки добавить
  }
  calcPrice();  
}

//Функция активирует/деактивирует кнопки добавления и удаления пассажира
function btnIncreaseDecrease(){
  var maxSeats=8; // max число пассажирских мест в такси
  var totalPassengers=parseInt($(".passengers__total").val()) ; //Получаем значение в счетчике пассажиров;
  var freeSeats=maxSeats-totalPassengers; //считаем количество свободных мест
  // В цикле проверяем количество пассажиров в каждой группе. Если оно 0 (для взрослых не 0, а 1),
  //то блокируем кнопку уменьшения, активируем кнопку добавления.
  $('.passengers__sub__number').each(function(index, value){
    if($(value).attr('id')=="adults"){
      var minPersons=1;
    }else{
      var minPersons=0;
    }

    if($(value).val()==minPersons){
      $(value).siblings('.decrease').prop("disabled", true);
      $(value).siblings('.increase').prop("disabled", false);
    }else{
      $(value).siblings('.decrease').prop("disabled", false);
    }

  });

  if(freeSeats<=0){
    $(".increase").prop("disabled", true); //если свободных мест нет, блокируем все кнопки добавить
  } else {
    $(".increase").prop("disabled", false); //иначе активируем кнопки добавить
  }

}

//- Устанавливает значения по умолчанию полей и кнопок в окне "Пассажиры"
function resetPassengers(){
  $('.passengers__sub__number').each(function(index, value){
    if($(value).attr('id')=="adults"){
      $(value).val('1');
    }else{
      $(value).val('0');
    }
  });
  var strPassengers=$(".label-adults").text() + ": 1; " + $(".label-children").text() + ": 0";
  $('input[name="passengers"]').val(strPassengers);
  $('.passengers__total').val(1);
  btnIncreaseDecrease();
  calcPrice();  
}

//================ End Passeggeri ====================//


//- Калькулятор стоимости
function calcPrice(){
  let pax= parseInt($(".passengers__total").val());
  //- Controllo per eliminare bug quando il prezzo non corrisponde al numero di passeggeri
  let totalPassengersToCheck = 0;
  $('.passengers__sub__number').each(function(index, value){
    totalPassengersToCheck = totalPassengersToCheck + parseInt($(this).val());
  });
  if(totalPassengersToCheck != pax){
    $(".passengers__total").val(totalPassengersToCheck);
    pax=totalPassengersToCheck;
  };
  //- ============
  let from=$("#from option:selected").val();
  let dest=$("#to option:selected").val();
  let isRoundTripChecked=$("input[name='roundtrip']").prop("checked");

  if((!isset(pax)) || (!isset(from)) || (!isset(dest))){
    return false;
  }

  let location1;
  let location2;
  if(keysMainPoints.includes(from)){
    location1=from;
    location2=dest;
  }else if(keysMainPoints.includes(dest)){
    location1=dest;
    location2=from;
  }else{
    alert("We are sorry, but there is an error in our system. Please, contact us!")
    return false;
  }

  let routeSelected = mainPoints[location1][location2]; //array of prices for selected route
  let paxType = routeSelected.length;
  let priceToShow;

  if(paxType==2){
    priceToShow = pax < 5 ? routeSelected[0] : routeSelected[1];
  }else if(paxType==8){
    priceToShow = routeSelected[pax-1];
  }else{
    alert("We are sorry, but there is an error in our system for this roate. Please, contact us!")
    //priceToShow = "";
    return false;
  }
  
  if(isRoundTripChecked){
    priceToShow = 2 * priceToShow;
  }
  
  $("input[name='price']").val("€ " + priceToShow);

}

//=========== FUNCTIONS generate FROM e TO ===============//

//- Генерирует список направлений в FROM в зависимости от языка
function generateFrom(){
  
  let keysLocations=[]; //массив всех направлений
  let keysLocationsTop=[]; //массив приоритетных направлений

  keysLocations=Object.keys(namesLocations);

  for(let i=0; i<keysMainPoints.length; i++){ 
    keysLocationsTop[i]=keysMainPoints[i]; //для FROM в качестве приоритетных направлений берем основные точки
  }
  addOptionsLocationsTopBottom("from", keysLocations, keysLocationsTop); // вывод направлений в FROM 
}


//- Генерирует список направлений в TO в зависимости от FROM
function generateTo(){
  
  let from=$('select[name="from"]').val();
  let keysLocations=[]; //массив ключей возможных направлений/главных точек из FROM
  let keysLocationsTop=[]; //массив приоритетных направлений, совпадает со свойством-массивом TOP из объекта текущего города arrTaormina/arrCataniaAirport/...
  let varOptionDefault=$('select[name="to"] option:first').text(); //define option in current language to use for select TO

  //- Очищаем TO и добавляем 'Select location' в качестве первой опции
  $('select[name="to"]').empty().append(new Option(varOptionDefault, ''));
  $('select[name="to"] option:first').prop({ 'disabled': true, 'hidden': true });
  
  //- Удаляем старую цену
  $("#price").val('');

  //- Выводим все возможные направления в TO, если FROM - одна из основных точек
  if(keysMainPoints.includes(from)){
    keysLocationsTop = mainPoints[from]['top']; // содержимое свойства 'top' = ключи приоритетных направлений
    
    keysLocations = Object.keys(mainPoints[from]); // define the keys of locations to output in TO, но нужно удалить первый элемент
    keysLocations.shift(); // удаляем первый элемент, т.к. это не направление, а ключ 'top'
    
    //let [firstElemTop, ...keysLocations] = keysPricesLocation; // destructuring assignment 

    addOptionsLocationsTopBottom("to", keysLocations, keysLocationsTop); //вывод направлений в TO
    return;
  }

  //- Выводим все возможные направления в TO, если FROM не является одной из основных точек
  let keysPricesLocation = []; 
  keysMainPoints.forEach(item => { 
    keysPricesLocation=Object.keys(mainPoints[item]); //- проверяем каждый объект с ценами нет ли среди его свойств направления FROM
    if(keysPricesLocation.includes(from)){ 
      keysLocations.push(item); // добавляем текущее направление (ключ) в массив возможных направлений
    }
  });
  //- вывод направлений в TO (без сортировки, в порядке написания основных точек)
  addOptionsLocations("to", keysLocations);

}

// Функция делит направления на приоритетные и нет и выводит их в браузере в FROM/TO
function addOptionsLocationsTopBottom(fieldName, keysLocations, keysLocationsTop){
  
  // Получаем ключи неприоритетных направлений
  let keysLocationsBottom = keysLocations.filter(item => !keysLocationsTop.includes(item));
  
  // Сортируем неприоритетные направления по названию и получаем их ключи
  let keysLocationsBottomSorted=sortLocations(keysLocationsBottom);

  // Выводим приоритетные направления в браузере
  addOptionsLocations(fieldName, keysLocationsTop);
  
  // Выводим разделитель
  $('select[name='+fieldName+']').append(new Option("-----", 'divider'));
  $("select[name="+fieldName+"] option[value='divider']").attr('disabled', true); //деактивируем разделитель
  
  // Выводим неприоритетные направления в браузере в алфавитном порядке
  addOptionsLocations(fieldName, keysLocationsBottomSorted);

}

// Функция выводит направления в браузере в FROM/TO
function addOptionsLocations(fieldName, keysLocations){
  keysLocations.forEach(item => {
    $('select[name='+fieldName+']').append(new Option(namesLocations[item], item));
  });
}

//- Функция сортировки по алфавиту
function sortLocations(keysLocationsToSort) {
  //- преобразуем объект в массив объектов, чтобы можно было воспользоваться сортировкой по полю
            // locations: {taormina: "Taormina", agrigento: "Agrigento", ...}
            // Object.entries(locations): [["taormina", "Taormina"], ["agrigento", "Agrigento"], ...]
            // locationsToSort: [{idLoc: "taormina", nameLoc: "Taormina"}, {idLoc: "agrigento"}, nameLoc: "Agrigento"}, ...]

  let locations = [];
  keysLocationsToSort.forEach(item => {
    locations[item] = namesLocations[item];
  });
  
  let locationsToSort = Object.entries(locations).map(([idLoc, nameLoc]) => ({ idLoc, nameLoc }));
  locationsToSort.sort((x, y) => x.nameLoc.localeCompare(y.nameLoc));
  
  let locationsSorted = {};
  locationsToSort.forEach(item => {
    locationsSorted[item.idLoc] = item.nameLoc;
  });

  return Object.keys(locationsSorted);
}


//============================ BOOKING STEP 1 ===============================//

// Переход к шагу 2 (Booking Details)
function goToStep2(){
  let flexStep2 = ".step2__changepart"; // класс, заменяющий .step2_changepart
  let curFlexStep2; // класс, заменяющий активный .step2_changepart

  //- проверяем валидность формы выбора направлений
  if (checkValidityForm("#booking", ".modal-step1") === false) {
    return(false);
  }

  //- скрываем шаг1 на странице бронирования
  $("#step1").addClass('hidden');

  //- очищаем формы шага2 и шага3
  $("#booking2")[0].reset();  //- очищаем форму деталей
  $("#booking2").removeClass('was-validated'); //-убирает подсветку ошибок, если мы уже вносили данные на шаге 2
  $("#formsummary")[0].reset();  //- очищаем итоговую форму

  //- устанавливаем дефолтные состояния у всех частей формы деталей и итоговой формы
  $(flexStep2).addClass("hidden");  //- скрываем все части
  $(flexStep2 + " input").removeAttr("required"); //- убираем у всех инпутов обязательность
  $(flexStep2 + " select").removeAttr("required"); //- убираем у всех селектов обязательность
  
  //- Определяем какие части формы активировать (city-city, airport-city...)
  let from=$('#from').val();
  let to=$('#to').val();
  let curRoute=getRouteType(from, to);
  $("#headeroneway").attr("typeroute", curRoute);

  
  //- Активируем нужные части формы на шаге2
  curFlexStep2=".step2__changepart[title='"+curRoute+"']";
  
  $(curFlexStep2).removeClass('hidden');
  $(curFlexStep2 + " select").attr("required", true); //делаем обязательными все селекты в активной части формы
  $(curFlexStep2 + " input").attr("required", true); //делаем обязательными все инпуты в активной части формы
  setDefaultStructure(curFlexStep2); // устанавливаем состояния полей структур по-умолчанию 

  // Формируем текст с пунктом отправления и назначения
  let locationName1=namesLocations[from];
  let locationName2=namesLocations[to];
  let strOneway=locationName1 + '  →  ' + locationName2;
  let strRoundtrip=locationName2 + '  →  ' + locationName1;

  $("#headeroneway").text(strOneway);
  
  // Показывает/скрывает то, что относится к трансферу в обратном направлении
  if ($('#roundTripCheck').is(':checked')){
    $("#headerroundtrip").text(strRoundtrip);
    $(curFlexStep2+" .tripReturn").removeClass('hidden');
    $(curFlexStep2+" .tripReturn input").prop('required', true);
    $(".step3 .tripReturn").removeClass('hidden');
    $('#booking2 .heading_rettangle').removeClass('hidden');
  }else{
    $(".form-group-roundtrip").addClass('hidden');
    $(curFlexStep2+" .tripReturn input").prop('required', false);
    $('.tripReturn').addClass('hidden');
    $('#booking2 .heading_rettangle').addClass('hidden');
  }






  // Показывает число пассажиров и стоимость
  $("#headerpersons").text($('#passengers').val());
  $("#headerprice").text($('#price').val());

  // Показывает шаг2
  $('.step2').removeClass('hidden');

  // Скрывает шаг3
  $('.step3').addClass('hidden');
  
  window.scrollTo(0, 0); //показываем верх страницы

}

//- Получает тип формы, которую нужно показать (city/city, airport/city и т.д.)
function getRouteType(from, to){ 
  let point1 = airports.includes(from) ? "airport" : ports.includes(from) ? "port" : "city";
  let point2 = airports.includes(to) ? "airport" : ports.includes(to) ? "port" : "city";
  return typesRoutes[point1][point2];
}

// Заранее устанавливаем структуру по умолчанию в шаге 2
function setDefaultStructure(curFlexPart){
  $(curFlexPart+" .info-structure-group").addClass('hidden'); //скрываем все структуры
  $(curFlexPart+" .radio-item-hotel").attr("checked", true); //активируем переключатель Hotel
  $(curFlexPart+" .info-hotel-group").removeClass('hidden'); //показываем вкладку с полями для Hotel
  $(curFlexPart+" .structure__wrap input").removeAttr("required"); //убираем все обязательные поля
  $(curFlexPart+" .tripThere .info-hotel-group input").attr("required", true); //делаем обязательными поля вкладки Hotel на пути туда
}


//============================ BOOKING STEP 2 ================================//

// Prepara l'info completo della struttura (per step 3)

function getFullInfoStructure(context){ //strObj=".booking2[title="+structureTitle+"']""
  //let struct=$("input:checked", context).val(); //typeStructure1a, typeStructure2a, typeStructure3...
  let curRadio=$("input:checked", context).attr("myid"); //radiohotel, radiohouse, radiobb...
  let fullInfo;

  switch(curRadio){
    case "radiohotel":
      fullInfo=$(".radio-item-hotel label", context).text()  + " " + $(".info-hotel-group input", context).val();
      break;
    case "radiohouse":
      fullInfo=$(".radio-item-house label", context).text() + " " + $(".house-name", context).val() + " – " + $(".house-address", context).val() + ", " + $(".house-number", context).val() + " – tel." + $(".house-phone", context).val();
      break;
    case "radiobb":
      fullInfo=$(".radio-item-bb label", context).text() + " " + $(".bb-name", context).val() + " – " + $(".bb-address", context).val() + ", " + $(".bb-number", context).val() + " – tel." + $(".bb-phone", context).val();
      break;
    default:
      fullInfo=$(".radio-item-other label", context).text() + " " + $(".other-name", context).val() + " – " + $(".other-address", context).val() + ", " + $(".other-number", context).val() + " – tel." + $(".other-phone", context).val();
  }

  return fullInfo;
}


// Переход к шагу 3 (Итог)
function goToStep3(){

  let curFlexStep3;
  let varFullStructure; //полное инфо о структуре 
  let varFullStructure1; //полное инфо о структуре 1
  let varFullStructure2; //полное инфо о структуре 2

  //проверяем выбран ли хоть один мессенджер
  if(isMessengerSelected() === false){
    $("#errorsModal .modal").removeClass('hidden');
    $("#errorsModal .modal-messengers").removeClass('hidden');
    $(".form__messengers").addClass('form__messengers--err');
    return false;
  }

  // проверяем валидность формы
  if(checkValidityForm("#booking2", ".modal-step2") === false){
    return false;
  }

  $("#formsummary")[0].reset(); //очищаем итоговую форму на случай, если были какие-то изменения
  $("#booking2").removeClass("was-validated");// ?????
  $(".form__messengers").removeClass('form__messengers--err');
  $("#step2").addClass('hidden');
  $(".step3__changepart").addClass("hidden");
  $("#step3").removeClass('hidden');
  window.scrollTo(0, 0);

  //- Получаем тип маршрута (city-city, airport-city...)
  let curRoute = $("#headeroneway").attr("typeroute");
  
  // Для проверки в php скрипте добавляем тип маршрута (city-city, airport-city...) и выбрано ли только туда или туда-обратно
  $('input[name="fromto"]').val(curRoute);
  $('input[name="idroute"]').val($("#roundTripCheck").is(":checked"));

  let name=$("#booking2 input[name='name']").val();
  let surname=$("#booking2 input[name='surname']").val();
  let varFullName = name + " " + surname;

  let prefix=$("#booking2 select[name='prefix'] option:selected").text();
  let phoneNumber=$("#booking2 input[name='phone']").val();
  let varPhone = prefix + " " + phoneNumber;

  let varDirection1=$('#headeroneway').text();
  let varDirection2=$('#headerroundtrip').text();
  
  // Добавляем в итоговую форму полное имя
  $('#formsummary input[name="name"]').val(varFullName);
  
  // Добавляем в итоговую форму email
  $('#formsummary input[name="email"]').val($('#booking2 input[name="email"]').val());

  // Добавляем в итоговую форму  телефон
  $('#formsummary input[name="phone"]').val(varPhone);

  // Добавляем в итоговую форму мессенджеры
  let allMess="";
  $("#booking2 input[name='messengers']").each(function (index, value){
    let curMess=$(value).val();
    if($(value).is(":checked")){
      $("."+curMess).removeClass('hidden');
      allMess=allMess+curMess+" ";
    }else{
      $("."+curMess).addClass('hidden');
    }
  });
  $("#formsummary input[name='messengers']").val(allMess);

  // Добавляем в итоговую форму маршрут откуда и куда
  $('#formsummary input[name="direction1"]').val(varDirection1);

  // Добавляем в итоговую форму обратный маршрут откуда и куда (если заказан)
  curFlexStep3=".step3__changepart[title='"+curRoute+"']";
  if ($('#roundTripCheck').is(':checked')){
    $('#formsummary input[name="direction2"]').val(varDirection2);
    $(".step3 .tripReturn").removeClass('hidden');
    $(".step3 .tripReturn "+curFlexStep3+" input").prop('required', true);
  }else{
    $(".step3 .tripReturn "+curFlexStep3+" input").prop('required', false);
    $('.step3 .tripReturn').addClass('hidden');
  }

  // Добавляем в итоговую форму полную информацию о структуре
  // (только для маршрутов city-city и <?>-city, city-<?>)
  if(curRoute=="city-city"){
   
    //- добавляем полное инфо о структуре1
    let objStructure1=$(".structure__wrap:first", ".step2__changepart[title='city-city']");
    varFullStructure1=getFullInfoStructure(objStructure1);
    $('#formsummary input[name="structure1a"]').val(varFullStructure1); 
    
    //- добавляем полное инфо о структуре2
    let objStructure2=$(".structure__wrap:last", ".step2__changepart[title='city-city']");
    varFullStructure2=getFullInfoStructure(objStructure2);
    $('#formsummary input[name="structure1b"]').val(varFullStructure2); //-копируем инфо о структуре2 (полное) в итоговую ф.
    
    //- добавляем полное инфо о структурах для return trip
    $(".sumStructure1", ".step3__changepart[title='city-city']").val(varFullStructure2);
    $(".sumStructure2", ".step3__changepart[title='city-city']").val(varFullStructure1);

  }else if(curRoute.indexOf('city')!=-1){
    let objStructure=$(".structure__wrap", ".step2__changepart[title='"+curRoute+"']");
    varFullStructure=getFullInfoStructure(objStructure);
    let objStructureName=objStructure.attr('title');
    $("#formsummary input[name='"+objStructureName+"']").val(varFullStructure); //-копируем инфо о структуре (полное) в итоговую ф.
    $(".sumStructure", ".step3__changepart[title='"+curRoute+"']").val(varFullStructure);  //-копируем инфо о структуре (полное) в итоговую ф. для обратного пути  

  }  
  
  $(".step3__changepart[title='"+curRoute+"']").removeClass('hidden');

  //Копируем в итоговую форму данные из формы деталей  (из шага 2)
  //- '.usualinput' - обычные инпуты, которые не нужно объединять с другой информацией
  $(".step3 .usualinput").each(function (index, value){
    var inputName=$(value).prop('name');
    $(value).val($(".step2 input[name='"+inputName+"']").val());
  });

  //Копируем в итоговую форму данные из формы Booking о пассажирах и маршруте (из шага 1)
  $('.summary-fromstep1').each(function (index, value){
    var inputName=$(value).prop('name');
    var varFromStep1=$('#booking input[name='+inputName+']').val();
    //- aggiungiamo il controllo per la corettezza del prezzo
    if($(value).prop('name')=="price"){
      calcPrice();
    }
    //- ==============
    if(varFromStep1==0){
      $(value).val(''); //если значение поля 0, то делаем reset поля в итоговой форме (понадобится для php)
      $(value).parent('.summary-group').addClass('hidden');
    } else {
      $(value).val(varFromStep1);
      $(value).parent().removeClass('hidden');     
    }

  });

  // Копируем в итоговую форму поле <Сообщение>
  $("#formsummary input[name='message']").val($('#booking2 textarea').val());

  // Для удобства чтения показываем инфо селектов в обычных div (селекты остаются скрыты)
  $('.from-select').each(function (index, value){ 
    var selectName=$(value).prop('name');
    $(value).val($('#booking2 select[name='+selectName+'] option:selected').text());
  });
   
  // Для удобства чтения показываем инфо инпутов в обычных div (инпуты остаются скрыты)
  $('.summary-text-input').each(function (index, value){
      $(value).text($(this).siblings('input').val());
  });
  
};

// Verifica se è stato scelto almeno 1 messenger
function isMessengerSelected() {
  let selected = false;
  // Itera attraverso gli input messengers
  $("#booking2 input[name='messengers']").each((index, item) => {
    if ($(item).is(":checked")) {
      selected = true; // Imposta la variabile a true se trova un elemento selezionato
      return false; // Interrompe l'iterazione
    }
  });
  return selected; // Restituisce lo stato di selezione
}



//===========================================================================//
//========= FUNCTIONS BOOKING TRANSFER CASE VINICOLE / EXCURSIONS  ========== //
//===========================================================================//

// Функция передает информации о винодельне/ экскурсии на страницу заказа

function moveInfoExcursionToBooking(fileName){
    var returnObj = JSON.parse(localStorage.getItem("myKey"));
    if(returnObj == null){
      $(".bookingexc-content").addClass('hidden'),
      $("#invaliddata").removeClass('hidden');
      return(false);      
    }

    $("#datatitle").val(returnObj['formtitle']);
    $(".datatitle--visible").text($("#datatitle").val());
    
    // Добавляем для случая заказа экскурсий
    if(fileName==="booking-excursion.php"){
      $("#dataguide").val(returnObj['formguide']);
    }  
    
    $("#datapersons").val(returnObj['formpersons']);
    $("#dataprice").val(returnObj['formprice']);
    localStorage.removeItem("myKey");
}




//===========================================================================//
//========================= FUNCTION INVIO FORM  ========================== //
//===========================================================================//

function doajax(activeform) {
  var formData = $(activeform).serialize();
  var actionUrl = $(activeform).attr('action');
  var idModal = (activeform === "#form-contacts") ? "#formContactsResult" : "#formBookingResult";

  $.ajax({
    type: "POST",
    url: actionUrl,
    data: formData,
    
    success: function (msg) {
      msg = $.trim(msg);

      var modal = $(idModal + " .modal");
      var modalSuccess = $(idModal + " .modal-success");
      var modalErrorGeneral = $(idModal + " .modal-error--general");
      var modalErrorRequired = $(idModal + " .modal-error--required");

      modal.removeClass('hidden');

      if (msg === "success") {
        modalSuccess.removeClass('hidden');
        $(activeform)[0].reset();
      } else if (msg.indexOf("empty") !== -1) {
        modalErrorRequired.removeClass('hidden');
        modalErrorRequired.find(".modal__message").empty().append(msg);
      } else {
        $(activeform)[0].reset();
        modalErrorGeneral.removeClass('hidden');
        modalErrorGeneral.find(".modal__message").empty().append(msg);
      }
    },
    beforeSend: function () {
      $(activeform).find(":input").prop("disabled", true);
      $(activeform + " .ajax-loader").css("display", "block");
    },
    complete: function () {
      $(activeform).find(":input").prop("disabled", false);
      $(activeform + " .ajax-loader").css("display", "none");
    }
  });
}



//- ================================================================//
//- ========================= VERIFICHE ============================//
//- ================================================================//


///- ПРОВЕРКИ!!!!!!!!!
// //Проверка нет ли в массивах с ценами направлений, которых нет в массиве всех направлений системы
// $(document).ready(function () {
// let myvar=Object.keys(arrSiracusa); //меняем массив для проверки
//   for(let i=1; i<myvar.length; i++){ 
//     if(!namesLocations[myvar[i]]){
//       console.log("Add this direction", myvar[i]);
//     }
//   }
// });


//- NOT DELETE!!!!!!!!!!!!!!!!!!!!!!!!!
//-проверяет указаны ли цены из B в A, если в системе есть цены из A в B
// $(document).ready(function (){
//   arrPointsNames.forEach(function(value1) {
//     arrPointsNames.forEach(function(value2){
//       if(arrPoints[value1][value2]){
//        if(arrPoints[value2][value1]==undefined) {
//         console.log(value2, value1);
//        }     
//       }
//     });
//   });
// });


// //Проверка соответствует ли цена из A в B цене из B в A
// $(document).ready(function () {
//   let arr1;
//   let arr2;
//   let obj;
//   for(let key1 in arrPoints){
//     obj=arrPoints[key1];
//     for(let key in obj){
//      if(arrPointsNames.includes(key)){
//        arr1=obj[key];
//        // console.log(key);
//        arr2=arrPoints[key][key1];
//        for(let i=0; i<arr1.length; i++){
//         if(arr1[i]!=arr2[i]){
//           console.log(key, key1);
//         }
//        }
//      }
//     }
//   }
// });