// ---------------------------------------------------//
// ----------------Directions and prices--------------//
// ---------------------------------------------------//


let namesLocations = {}; // названия всех направлений в нашей системе (ключ+значение)

function getNamesLocationsByLanguage(language){
  if(language=="ru"){
      namesLocations = {
        cataniaairport: "Катания аэропорт",
        cataniacenter: "Катания центр",
        cataniaport: "Катания порт",
        taormina: "Таормина",
        castelmola: "Кастельмола",
        giardininaxos: "Джардини Наксос",
        letojanni: "Летоянни",
        siracusa: "Сиракуза",
        augusta: "Аугуста",
        brucoli: "Бруколи",
        noto: "Ното",
        fontanebianche: "Фонтане Бьянке",
        lidodinoto: "Лидо-ди-Ното",
        marzamemi: "Мардзамеми", 
        ragusa: "Рагуза", 
        marinadiragusa: "Марина-ди-Рагуза", 
        modica: "Модика", 
        marinadimodica: "Марина-ди-Модика", 
        comisocenter: "Комизо центр",  
        comisoairport: "Комизо аэропорт", 
        vittoria: "Витториа", 
        lentini: "Лентини", 
        carlentini: "Карлентини", 
        vizzini: "Виццини", 
        francofonte: "Франкофонте", 
        agrigento: "Агридженто", 
        gela: "Джела", 
        licata: "Ликата", 
        caltagirone: "Кальтаджироне", 
        caltanissetta: "Кальтаниссетта", 
        enna: "Энна",  
        piazzaarmerina: "Пьяцца-Армерина", 
        cefalu: "Чефалу", 
        palermocenter: "Палермо центр", 
        mondello: "Монделло", 
        palermoairport: "Палермо аэропорт", 
        bagheria: "Багериа", 
        santaflavia: "Санта Флавиа", 
        villabate: "Виллабате", 
        campofelicediroccella: "Кампо-Феличе-ди-Рочелла", 
        santagatadimilitello: "Сант-Агата-ди-Милителло", 
        capodorlando: "Капо д'Орландо", 
        gioiosamarea: "Джойоза Мареа", 
        brolo: "Броло", 
        patti: "Патти", 
        barcellonapozzodigotto: "Барчелона Поццо-ди-Готто", 
        portorosaport: "Портороза порт", 
        milazzoport: "Милаццо порт", 
        milazzocenter: "Милаццо центр", 
        messinaport: "Мессина порт", 
        messinacenter: "Мессина центр", 
        aliterme: "Али Терме", 
        nizzadisicilia: "Ницца-ди-Сицилия", 
        roccalumera: "Роккалумера", 
        furcisiculo: "Фурчи Сикуло", 
        santateresa: "Санта Тереза", 
        savoca: "Савока", 
        forzadagro: "Форца д'Агро", 
        santalessio: "Сант-Алессио", 
        randazzo: "Рандаццо", 
        bronte: "Бронте", 
        linguaglossa: "Лингуаглосса", 
        santavenerina: "Санта Венерина", 
        zafferana: "Дзафферана", 
        milo: "Мило", 
        solicchiata: "Соликкьята", 
        rovittello: "Ровиттелло", 
        piedimonteetneo: "Пьедимонте Этнео",  
        fiumefreddodisicilia: "Фьюмефреддо-ди-Сичилья", 
        calatabiano: "Калатабьяно", 
        mascali: "Маскали", 
        giarre: "Джарре", 
        acireale: "Ачиреале", 
        ripostoport: "Рипосто порт", 
        ribera: "Рибера", 
        sciacca: "Шакка", 
        mazaradelvallo: "Мадзара-дел-Валло", 
        marsala: "Марсала",  
        trapanicenter: "Трапани центр",  
        trapaniairport: "Трапани аэропорт",  
        sanvitolocapo: "Сан-Вито-ло-Капо", 
        pozzallo: "Поццалло", 
      }; 
  }else if(language=="it"){
      namesLocations = {
        cataniaairport: "Catania Aeroporto",
        cataniacenter: "Catania Centro",
        cataniaport: "Catania Porto",
        taormina: "Taormina",
        castelmola: "Castelmola",
        giardininaxos: "Giardini Naxos",
        letojanni: "Letojanni",
        siracusa: "Siracusa",
        augusta: "Augusta",
        brucoli: "Brucoli",
        noto: "Noto",
        fontanebianche: "Fontane Bianche",
        lidodinoto: "Lido di Noto",
        marzamemi: "Marzamemi", 
        ragusa: "Ragusa", 
        marinadiragusa: "Marina di Ragusa", 
        modica: "Modica", 
        marinadimodica: "Marina di Modica", 
        comisocenter: "Comiso Centro",  
        comisoairport: "Comiso Aeroporto", 
        vittoria: "Vittoria", 
        lentini: "Lentini", 
        carlentini: "Carlentini", 
        vizzini: "Vizzini", 
        francofonte: "Francofonte", 
        agrigento: "Agrigento", 
        gela: "Gela", 
        licata: "Licata", 
        caltagirone: "Caltagirone", 
        caltanissetta: "Caltanissetta", 
        enna: "Enna",  
        piazzaarmerina: "Piazza Armerina", 
        cefalu: "Cefalù", 
        palermocenter: "Palermo Centro", 
        mondello: "Mondello", 
        palermoairport: "Palermo Aeroporto", 
        bagheria: "Bagheria", 
        santaflavia: "Santa Flavia", 
        villabate: "Villabate", 
        campofelicediroccella: "Campo Felice di Roccella", 
        santagatadimilitello: "Sant’Agata di Militello", 
        capodorlando: "Capo D’Orlando", 
        gioiosamarea: "Gioiosa Marea", 
        brolo: "Brolo", 
        patti: "Patti", 
        barcellonapozzodigotto: "Barcellona Pozzo di Gotto", 
        portorosaport: "Portorosa Porto", 
        milazzoport: "Milazzo Porto", 
        milazzocenter: "Milazzo Centro", 
        messinaport: "Messina Porto", 
        messinacenter: "Messina Centro", 
        aliterme: "Ali Terme", 
        nizzadisicilia: "Nizza di Sicilia", 
        roccalumera: "Roccalumera", 
        furcisiculo: "Furci Siculo", 
        santateresa: "Santa Teresa", 
        savoca: "Savoca", 
        forzadagro: "Forza D’Agrò", 
        santalessio: "Sant’Alessio", 
        randazzo: "Randazzo", 
        bronte: "Bronte", 
        linguaglossa: "Linguaglossa", 
        santavenerina: "Santa Venerina", 
        zafferana: "Zafferana", 
        milo: "Milo", 
        solicchiata: "Solicchiata", 
        rovittello: "Rovittello", 
        piedimonteetneo: "Piedimonte Etneo",  
        fiumefreddodisicilia: "Fiumefreddo di Sicilia", 
        calatabiano: "Calatabiano", 
        mascali: "Mascali", 
        giarre: "Giarre", 
        acireale: "Acireale", 
        ripostoport: "Riposto porto", 
        ribera: "Ribera", 
        sciacca: "Sciacca", 
        mazaradelvallo: "Mazara del Vallo", 
        marsala: "Marsala",  
        trapanicenter: "Trapani Centro",  
        trapaniairport: "Trapani Aeroporto",  
        sanvitolocapo: "San Vito lo Capo", 
        pozzallo: "Pozzallo", 
      }; 
  }else{
      namesLocations = {
        cataniaairport: "Catania Airport",
        cataniacenter: "Catania Center",
        cataniaport: "Catania Port",
        taormina: "Taormina",
        castelmola: "Castelmola",
        giardininaxos: "Giardini Naxos",
        letojanni: "Letojanni",
        siracusa: "Siracusa",
        augusta: "Augusta",
        brucoli: "Brucoli",
        noto: "Noto",
        fontanebianche: "Fontane Bianche",
        lidodinoto: "Lido di Noto",
        marzamemi: "Marzamemi", 
        ragusa: "Ragusa", 
        marinadiragusa: "Marina di Ragusa", 
        modica: "Modica", 
        marinadimodica: "Marina di Modica", 
        comisocenter: "Comiso Center",  
        comisoairport: "Comiso Airport", 
        vittoria: "Vittoria", 
        lentini: "Lentini", 
        carlentini: "Carlentini", 
        vizzini: "Vizzini", 
        francofonte: "Francofonte", 
        agrigento: "Agrigento", 
        gela: "Gela", 
        licata: "Licata", 
        caltagirone: "Caltagirone", 
        caltanissetta: "Caltanissetta", 
        enna: "Enna",  
        piazzaarmerina: "Piazza Armerina", 
        cefalu: "Cefalù", 
        palermocenter: "Palermo Center", 
        mondello: "Mondello", 
        palermoairport: "Palermo Airport", 
        bagheria: "Bagheria", 
        santaflavia: "Santa Flavia", 
        villabate: "Villabate", 
        campofelicediroccella: "Campo Felice di Roccella", 
        santagatadimilitello: "Sant’Agata di Militello", 
        capodorlando: "Capo D’Orlando", 
        gioiosamarea: "Gioiosa Marea", 
        brolo: "Brolo", 
        patti: "Patti", 
        barcellonapozzodigotto: "Barcellona Pozzo di Gotto", 
        portorosaport: "Portorosa Port", 
        milazzoport: "Milazzo Port", 
        milazzocenter: "Milazzo Center", 
        messinaport: "Messina Port", 
        messinacenter: "Messina Center", 
        aliterme: "Ali Terme", 
        nizzadisicilia: "Nizza di Sicilia", 
        roccalumera: "Roccalumera", 
        furcisiculo: "Furci Siculo", 
        santateresa: "Santa Teresa", 
        savoca: "Savoca", 
        forzadagro: "Forza D’Agrò", 
        santalessio: "Sant’Alessio", 
        randazzo: "Randazzo", 
        bronte: "Bronte", 
        linguaglossa: "Linguaglossa", 
        santavenerina: "Santa Venerina", 
        zafferana: "Zafferana", 
        milo: "Milo", 
        solicchiata: "Solicchiata", 
        rovittello: "Rovittello", 
        piedimonteetneo: "Piedimonte Etneo",  
        fiumefreddodisicilia: "Fiumefreddo di Sicilia", 
        calatabiano: "Calatabiano", 
        mascali: "Mascali", 
        giarre: "Giarre", 
        acireale: "Acireale", 
        ripostoport: "Riposto port", 
        ribera: "Ribera", 
        sciacca: "Sciacca", 
        mazaradelvallo: "Mazara del Vallo", 
        marsala: "Marsala",  
        trapanicenter: "Trapani Center",  
        trapaniairport: "Trapani Airport",  
        sanvitolocapo: "San Vito lo Capo", 
        pozzallo: "Pozzallo", 
      }; 
  }
}


var pricesTaormina = {
  top: [
   "cataniaairport",
   "cataniacenter",
   "messinacenter",
   "messinaport",
   "milazzocenter", 
   "milazzoport", 
   "portorosaport", 
   "siracusa"
  ], 
  cataniaairport: [80, 80, 90, 110, 120, 130, 140, 150],
  cataniacenter: [80, 80, 90, 110, 120, 130, 140, 150],
  cataniaport: [80, 80, 90, 110, 120, 130, 140, 150],
  siracusa: [170, 200],
  augusta: [140, 170],
  brucoli: [135, 160],
  noto: [235, 280],
  fontanebianche: [250, 290],
  lidodinoto: [250, 290],
  marzamemi: [295, 345],
  ragusa: [300, 350],
  marinadiragusa: [355, 400],
  modica: [320, 370],
  marinadimodica: [355, 400],
  comisocenter: [300, 350],
  comisoairport: [300, 350],
  vittoria: [320, 370],
  lentini: [150, 170, 180, 210, 230, 250, 280, 285],
  carlentini: [150, 170, 180, 210, 230, 250, 280, 285],
  vizzini: [230, 280],
  francofonte: [195, 245],
  agrigento: [350, 400],
  gela: [350, 400],
  licata: [335, 385],
  caltagirone: [300, 350],
  caltanissetta: [340, 390],
  enna: [310, 360],
  piazzaarmerina : [350, 450],
  cefalu: [400, 420, 450, 450, 500, 550, 600, 650],
  palermocenter: [490, 520, 550, 550, 600, 650, 700, 750],
  mondello: [500, 550, 600, 650, 700, 750, 800, 850],
  palermoairport: [550, 600, 650, 700, 750, 750, 800, 850],
  bagheria: [420, 450],
  santaflavia: [420, 450],
  villabate: [430, 465],
  campofelicediroccella: [385, 445],
  santagatadimilitello: [315, 380],
  capodorlando: [285, 340],
  gioiosamarea: [295, 340],
  brolo: [280, 315],
  patti: [230, 280],
  barcellonapozzodigotto: [135, 170],
  portorosaport: [200, 240],
  milazzoport: [150, 150, 170, 190, 200, 220, 230, 250],
  milazzocenter: [150, 150, 170, 190, 200, 220, 230, 250],
  messinaport: [110, 120, 130, 150, 160, 180, 190, 220],
  messinacenter: [110, 120, 130, 150, 160, 180, 190, 220],
  aliterme: [95, 135],
  nizzadisicilia: [90, 105],
  roccalumera: [85, 100],
  furcisiculo: [80, 95],
  santateresa: [75, 95],
  savoca: [125, 155],
  forzadagro: [70, 80],
  santalessio: [75, 90],
  randazzo: [120, 150],
  bronte: [150, 180],
  linguaglossa: [90, 130],
  santavenerina: [80, 110],
  zafferana: [95, 130],
  milo: [110, 140],
  solicchiata: [130, 170],
  rovittello: [110, 150],
  piedimonteetneo: [75, 95],
  fiumefreddodisicilia: [45, 65],
  calatabiano: [35, 50],
  mascali: [55, 70],
  giarre : [85, 110],
  acireale : [85, 100],
  ripostoport : [80, 110],
  ribera: [400, 450],
  sciacca: [430, 485],
  mazaradelvallo: [480, 530],
  marsala: [530, 600],
  trapanicenter: [800, 950],
  trapaniairport: [800, 950],
  sanvitolocapo: [900, 1000],
  pozzallo: [295, 345],
};


var pricesCataniaAirport = {
  top: [
    "taormina",
    "giardininaxos",
    "letojanni",
    "forzadagro",
    "messinacenter",
    "messinaport",
    "milazzocenter",
    "milazzoport",
    "portorosaport",
    "siracusa",
    "ripostoport",
    "cefalu"
  ],
  taormina: [80, 80, 90, 110, 120, 130, 140, 150],
  giardininaxos: [80, 80, 90, 110, 120, 130, 140, 150],
  letojanni: [80, 80, 90, 110, 120, 130, 140, 150],
  castelmola: [90, 90, 100, 110, 120, 130, 140, 150],
  siracusa: [140, 170],
  augusta: [100, 140],
  brucoli: [95, 120],
  noto: [170, 200],
  fontanebianche: [160, 190],
  lidodinoto: [180, 210],
  marzamemi: [250, 285],
  ragusa: [245, 285],
  marinadiragusa: [295, 320],
  modica: [265, 285],
  marinadimodica: [290, 315],
  comisocenter: [245, 285],
  comisoairport: [245, 285],
  vittoria: [270, 295],
  lentini: [90, 120],
  carlentini: [90, 120],
  vizzini: [165, 200],
  francofonte: [140, 170],
  agrigento: [290, 320],
  gela: [290, 320],
  licata: [290, 320],
  caltagirone: [250, 285],
  caltanissetta: [260, 295],
  enna: [250, 290],
  piazzaarmerina: [250, 285],
  cefalu: [330, 385],
  palermocenter: [400, 450],
  mondello: [430, 485],
  palermoairport: [450, 500],
  bagheria: [360, 400],
  santaflavia: [360, 400],
  villabate: [370, 410],
  campofelicediroccella: [280, 340],
  santagatadimilitello: [450, 450, 485, 520, 550, 600, 650, 700],
  capodorlando: [420, 420, 450, 480, 550, 590, 620, 650],
  gioiosamarea: [330, 330, 350, 380, 410, 440, 470, 520],
  brolo: [350, 350, 380, 400, 420, 450, 480, 550],
  patti: [280, 280, 300, 320, 350, 380, 410, 440],
  barcellonapozzodigotto: [230, 230, 250, 270, 290, 320, 350, 380],
  portorosaport: [250, 250, 270, 290, 310, 350, 380, 400],
  milazzoport: [180, 180, 190, 200, 220, 230, 250, 290],
  milazzocenter: [180, 180, 190, 200, 220, 230, 250, 290],
  messinaport: [150, 150, 160, 170, 180, 190, 200, 220],
  messinacenter: [150, 150, 160, 170, 180, 190, 200, 220],
  aliterme: [140, 175],
  nizzadisicilia: [135, 160],
  roccalumera: [125, 155],
  furcisiculo: [120, 145],
  santateresa: [110, 130],
  savoca: [145, 175],
  forzadagro: [100, 100, 110, 120, 125, 130, 140, 150],
  santalessio: [110, 130],
  randazzo: [200, 250],
  bronte: [220, 270],
  linguaglossa: [140, 180],
  santavenerina: [110, 140],
  zafferana: [135, 160],
  milo: [140, 180],
  solicchiata: [160, 200],
  rovittello: [150, 190],
  piedimonteetneo: [115, 145],
  fiumefreddodisicilia: [90, 120],
  calatabiano: [90, 120],
  mascali: [90, 120],
  giarre: [90, 120],
  acireale: [80, 110],
  ripostoport: [90, 120],
  ribera: [330, 370],
  sciacca: [360, 400],
  mazaradelvallo: [400, 460],
  marsala: [450, 495],
  trapanicenter: [600, 700],
  trapaniairport: [600, 700],
  sanvitolocapo: [650, 750],
  pozzallo: [245, 280],
};


var pricesCastelmola = {
  top: [
   "cataniaairport",
   "cataniacenter",
   "messinacenter",
   "messinaport",
   "milazzocenter", 
   "milazzoport", 
   "portorosaport", 
   "siracusa"
  ],
  cataniaairport: [90, 90, 100, 110, 120, 130, 140, 150],
  cataniacenter: [90, 90, 100, 110, 120, 130, 140, 150],
  cataniaport: [90, 90, 100, 110, 120, 130, 140, 150],   
  siracusa: [170, 200],
  augusta: [140, 170],
  brucoli: [135, 160],
  noto: [235, 280],
  fontanebianche: [185, 230],
  lidodinoto: [240, 290],
  marzamemi: [295, 345],
  ragusa: [300, 350],
  marinadiragusa: [355, 400],
  modica: [320, 370],
  marinadimodica: [355, 400],
  comisocenter: [300, 350],
  comisoairport: [300, 350],
  vittoria: [320, 370],
  lentini: [150, 180],
  carlentini: [155, 185],
  vizzini: [230, 280],
  francofonte: [195, 245],
  agrigento: [350, 400],
  gela: [350, 400],
  licata: [335, 385],
  caltagirone: [300, 350],
  caltanissetta: [340, 390], 
  enna: [310, 360], 
  piazzaarmerina: [330, 395], 
  cefalu: [350, 400],
  palermocenter: [490, 540],
  mondello: [540, 585],
  palermoairport: [600, 660],
  bagheria: [420, 450],
  santaflavia: [420, 450],
  villabate: [430, 465],
  campofelicediroccella: [385, 445],
  santagatadimilitello: [315, 380],
  capodorlando: [285, 340],
  gioiosamarea: [295, 340],
  brolo: [280, 315],
  patti: [230, 280],
  barcellonapozzodigotto: [135, 170],
  portorosaport: [200, 240],
  milazzoport: [185, 195, 200, 220, 260, 260, 260, 260],
  milazzocenter: [185, 195, 200, 220, 260, 260, 260, 260],
  messinaport: [130, 150, 160, 170, 180, 200, 220, 250],
  messinacenter: [130, 150, 160, 170, 180, 200, 220, 250],
  aliterme: [85, 100],
  nizzadisicilia: [90, 105],
  roccalumera: [85, 100],
  furcisiculo: [80, 95],
  santateresa: [75, 95],
  savoca: [125, 155],
  forzadagro: [70, 80],
  santalessio: [75, 90],
  randazzo: [150, 180],
  bronte: [175, 210],
  linguaglossa: [95, 130],
  santavenerina: [80, 110],
  zafferana: [95, 130],
  milo: [110, 140],
  solicchiata: [130, 170],
  rovittello: [110, 150],
  piedimonteetneo: [75, 95], 
  fiumefreddodisicilia: [45, 65],
  calatabiano: [35, 50],
  mascali: [55, 70],
  giarre: [85, 110],
  acireale: [80, 99],
  ripostoport: [80, 110],
  ribera: [400, 450],
  sciacca: [430, 485],
  mazaradelvallo: [480, 530],
  marsala: [530, 600],
  trapanicenter: [800, 950],
  trapaniairport: [800, 950],
  sanvitolocapo: [900, 1000],
  pozzallo: [310, 350],
};


var pricesSiracusa = {
  top: [
    "cataniaairport",
    "cataniacenter",
    "taormina",
    "giardininaxos",
    "letojanni",
    "forzadagro",
    "messinacenter",
    "messinaport",
  ],
  cataniaairport: [140, 170],
  cataniacenter: [140, 170],
  cataniaport: [140, 170],
  taormina: [170, 200],
  giardininaxos: [170, 200],
  letojanni: [170, 200],
  castelmola: [170, 200],
  cefalu: [560, 650],
  palermocenter: [650, 700],
  mondello: [700, 750],
  palermoairport: [735, 780],
  bagheria: [530, 620],
  santaflavia: [530, 620],
  villabate: [560, 650],
  campofelicediroccella: [500, 540],
  santagatadimilitello: [735, 780],
  capodorlando: [735, 780],
  gioiosamarea: [700, 750],
  brolo: [680, 735],
  patti: [650, 700],
  barcellonapozzodigotto: [630, 670],
  portorosaport: [650, 700],
  milazzoport: [320, 380],
  milazzocenter: [320, 380],
  messinaport: [270, 315],
  messinacenter: [270, 315],
  roccalumera: [550, 585],
  furcisiculo: [550, 585],
  santateresa: [520, 550],
  savoca: [550, 585],
  forzadagro: [470, 500],
  santalessio: [490, 525],
  randazzo: [330, 385],
  bronte: [360, 410],
  linguaglossa: [185, 220],
  santavenerina: [170, 200],
  zafferana: [185, 220],
  milo: [200, 250],
  solicchiata: [235, 285],
  rovittello: [200, 250],
  piedimonteetneo: [170, 200],
  fiumefreddodisicilia: [160, 180],
  calatabiano: [160, 180],
  mascali: [150, 165],
  giarre: [135, 150],
  acireale: [125, 135],
  ripostoport: [170, 200],
};


var pricesMessina = {
  top: [
   "cataniaairport",
   "cataniacenter",
   "taormina",
   "giardininaxos",
   "letojanni", 
   "siracusa"
  ], 
  cataniaairport: [150, 150, 160, 170, 180, 190, 200, 220],
  cataniacenter: [150, 150, 160, 170, 180, 190, 200, 220],
  cataniaport: [150, 150, 160, 170, 180, 190, 200, 220],
  taormina: [110, 120, 130, 150, 160, 180, 190, 220],
  giardininaxos: [110, 120, 130, 150, 160, 180, 190, 220],
  letojanni: [110, 120, 130, 150, 160, 180, 190, 220],
  castelmola: [130, 150, 160, 170, 180, 200, 220, 250],
  siracusa: [270, 315],
  augusta: [220, 260],
  brucoli: [215, 260],
  noto: [320, 370],
  fontanebianche: [285, 315],
  lidodinoto: [340, 395],
  marzamemi: [400, 450],
  ragusa: [375, 435],
  marinadiragusa: [410, 465],
  modica: [395, 440],
  marinadimodica: [440, 500],
  comisocenter: [375, 430],
  comisoairport: [375, 430],
  vittoria: [400, 435],
  lentini: [250, 295],
  carlentini: [250, 295],
  vizzini: [320, 385],
  francofonte: [285, 335],
  agrigento: [460, 530],
  gela: [450, 510],
  licata: [435, 500],
  caltagirone: [395, 440],
  caltanissetta: [410, 485],
  enna: [400, 455],
  piazzaarmerina: [425, 500],
  cefalu: [440, 485],
  palermocenter: [500, 600],
  mondello: [550, 600],
  palermoairport: [645, 695],
  bagheria: [430, 465],
  santaflavia: [430, 465],
  villabate: [400, 440],
  campofelicediroccella: [385, 445],
  roccalumera: [85, 100],
  furcisiculo: [80, 95],
  santateresa: [75, 95],
  savoca: [125, 155],
  forzadagro: [95, 110],
  santalessio: [90, 100],
  randazzo: [250, 270],
  bronte: [295, 320],
  linguaglossa: [220, 250],
  santavenerina: [170, 235],
  zafferana: [220, 250],
  milo: [210, 250],
  solicchiata: [230, 265],
  rovittello: [200, 250],
  piedimonteetneo: [175, 200],
  fiumefreddodisicilia: [130, 150, 160, 170, 180, 200, 220, 250],
  calatabiano: [130, 150, 160, 170, 180, 200, 220, 250],
  mascali: [155, 180],
  giarre: [130, 140, 150, 160, 170, 180, 190, 210],
  acireale: [140, 170],
  ripostoport: [145, 185],
  ribera: [500, 565],
  sciacca: [565, 620],
  mazaradelvallo: [580, 630],
  marsala: [630, 695],
  trapanicenter: [770, 850],
  trapaniairport: [770, 850],
  sanvitolocapo: [915, 995],
  pozzallo: [400, 465],
};



var pricesMilazzo = {
  top: [
   "cataniaairport",
   "cataniacenter",
   "taormina",
   "giardininaxos",
   "letojanni",
   "siracusa"
  ],
  cataniaairport: [180, 180, 190, 200, 220, 230, 250, 290],
  cataniacenter: [180, 180, 190, 200, 220, 230, 250, 290],
  cataniaport: [180, 180, 190, 200, 220, 230, 250, 290],
  taormina: [150, 150, 170, 190, 200, 220, 230, 250],
  giardininaxos: [150, 150, 170, 190, 200, 220, 230, 250],
  letojanni: [150, 150, 170, 190, 200, 220, 230, 250],
  castelmola: [185, 195, 200, 220, 260, 260, 260, 260],
  siracusa: [320, 380],
  augusta: [245, 285],
  brucoli: [235, 290],
  noto: [340, 410],
  fontanebianche: [310, 350],
  lidodinoto: [365, 435],
  marzamemi: [425, 475],
  ragusa: [400, 470],
  marinadiragusa: [435, 510],
  modica: [410, 475],
  marinadimodica: [485, 520],
  comisocenter: [400, 470],
  comisoairport: [400, 470],
  vittoria: [445, 485],
  lentini: [275, 340],
  carlentini: [275, 340],
  vizzini: [350, 410],
  francofonte: [330, 385],
  agrigento: [500, 575],
  gela: [500, 575],
  licata: [550, 600],
  caltagirone: [440, 500],
  caltanissetta: [465, 520],
  enna: [440, 500],
  piazzaarmerina: [485, 500],
  cefalu: [400, 440],
  palermocenter: [450, 500],
  mondello: [500, 550],
  palermoairport: [600, 650],
  bagheria: [395, 430],
  santaflavia: [400, 410],
  villabate: [370, 400],
  campofelicediroccella: [350, 390],
  roccalumera: [99, 115],
  furcisiculo: [99, 115],
  santateresa: [105, 115],
  savoca: [150, 185],
  forzadagro: [130, 150],
  santalessio: [99, 115],
  randazzo: [285, 315],
  bronte: [320, 355],
  linguaglossa: [215, 260],
  santavenerina: [200, 240],
  zafferana: [215, 260],
  milo: [240, 275],
  solicchiata: [250, 300],
  rovittello: [230, 285],
  piedimonteetneo: [195, 220],
  fiumefreddodisicilia: [150, 200],
  calatabiano: [140, 175],
  mascali: [155, 190],
  giarre: [165, 200],
  acireale: [165, 210],
  ripostoport: [185, 220],
  ribera: [550, 600],
  sciacca: [610, 700],
  mazaradelvallo: [680, 750],
  marsala: [730, 800],
  trapanicenter: [800, 875],
  trapaniairport: [800, 875],
  sanvitolocapo: [930, 1000],
  pozzallo: [440, 500],
};



var pricesComiso = {
  top: [
   "cataniaairport",
   "cataniacenter",
   "taormina",
   "giardininaxos",
   "letojanni", 
   "siracusa",
   "messinacenter",
   "messinaport",
   "milazzocenter", 
   "milazzoport", 
   "portorosaport", 
  ], 
  cataniaairport: [245, 285],
  cataniacenter: [245, 285],
  cataniaport: [245, 285],
  taormina: [300, 350],
  giardininaxos: [300, 350],
  letojanni: [300, 350],
  castelmola: [300, 350],
  cefalu: [660, 750],
  palermocenter: [750, 800],
  mondello: [800, 850],
  palermoairport: [835, 880],
  bagheria: [630, 720],
  santaflavia: [630, 720],
  villabate: [660, 750],
  campofelicediroccella: [600, 640],
  santagatadimilitello: [660, 750],
  capodorlando: [635, 700],
  gioiosamarea: [600, 670],
  brolo: [580, 660],
  patti: [560, 640],
  barcellonapozzodigotto: [540, 610],
  portorosaport: [560, 640],
  milazzoport: [400, 470],
  milazzocenter: [400, 470],
  messinaport: [375, 430],
  messinacenter: [375, 430],
  roccalumera: [435, 485],
  furcisiculo: [435, 485],
  santateresa: [400, 450],
  savoca: [435, 485],
  forzadagro: [350, 395],
  santalessio: [375, 420],
  randazzo: [420, 485],
  bronte: [455, 500],
  linguaglossa: [330, 385],
  santavenerina: [300, 350],
  zafferana: [330, 385],
  milo: [350, 410],
  solicchiata: [375, 445],
  rovittello: [350, 410],
  piedimonteetneo: [300, 350],
  fiumefreddodisicilia: [300, 350],
  calatabiano: [300, 350],
  mascali: [270, 310],
  giarre: [250, 285],
  acireale: [235, 265],
  ripostoport: [300, 350],
};


//одинаковые Catania airport = Catania center = Catania port
//Messina port = Messina center
//Milazzo port = Milazzo center
//Taormina = Giardini Naxos = Letojanni = (кроме цен до аэропорта Castelmola)


//- Объект с основными точками
// Значения свойств - объект с соответсвующими направлениями из этой точки
//Создаем, чтобы была связь между элементами списка SELECT и соответствующими направлениями с ценами
var mainPoints = { 
  cataniaairport: pricesCataniaAirport,
  cataniacenter: pricesCataniaAirport, //совпадает с аэропортом
  cataniaport: pricesCataniaAirport, //совпадает с аэропортом
  taormina: pricesTaormina,
  giardininaxos: pricesTaormina,
  letojanni: pricesTaormina,
  castelmola: pricesCastelmola,
  messinacenter: pricesMessina,
  messinaport: pricesMessina,
  milazzocenter: pricesMilazzo,
  milazzoport: pricesMilazzo,
  siracusa: pricesSiracusa,
  comisoairport: pricesComiso,
};

let airports = ["cataniaairport", "palermoairport", "comisoairport", "trapaniairport"];
let ports = ["milazzoport", "messinaport", "cataniaport", "ripostoport", "portorosaport"];

var keysMainPoints = Object.keys(mainPoints); //получаем массив ("cataniaairport", "cataniacenter", "taormina" ...)

let typesRoutes = {
  "airport": {"city": "airport-city", "port": "airport-port", "airport": "airport-airport"},
  "port": {"city": "port-city", "airport": "port-airport", "port": "port-port"},
  "city": {"airport": "city-airport", "port": "city-port", "city": "city-city"},
};

